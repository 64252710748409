@keyframes fadein {
  from {
    opacity: 0;
    padding-top: 30px;
  }
  to {
    padding-top: 0px;
    opacity: 1;
  }
}
.user {
  position: absolute;
  left: 10px;
  top: 20px;
}
.logoLanding {
  height: 80px;
}
.landing-wrapper {
  * {
    font-family: Montserrat !important;
  }
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
  nav {
    background-color: #000a0b40;
  }
  h2 {
    font-family: Montserrat SemiBold;
    font-size: 34px;
    font-style: normal;
    font-weight: 900;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.mobile {
  display: inherit;
  @media (min-width:961px)  {
    display: none;
   }
}
.desktop {
  display: none;
  @media (min-width:961px)  {
    display: inherit;
   }
}

.landing-header {
  height: 580px;

  background-image: url('../assets/imgs/bgLogin.JPG'),
    linear-gradient(to right top, #051937, #012946, #003955, #024962, #125a6d);
  background-blend-mode: overlay;

  background-attachment: fixed;
  background-position: top center;
  // background-attachment: fixed;
  background-position: bottom center;
  background-size: cover;
  @media (max-width: 961px) {
    background-attachment: local;
    background-size: cover;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-header-wrapper {
  position: absolute;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  width: 100vw;
}
.container {
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  a {
    z-index: 10;
  }
}

.landing-header-grandient {
  position: absolute;
  left: 0;
  height: 70px;
  margin-top: 5px;
  min-width: 300px;
}
a {
  text-decoration: none !important;
}
.landing-header-buttons {
  display: flex;
  text-decoration: none !important;
  a {
    text-decoration: none !important;
  }
  li {
    a {
      text-decoration: none !important;
      color: white;
    }
    padding: 20px 15px;
    margin-left: 20px;
    font-weight: 700;
    color: white;
    &.areaaluno {
      transition: ease all 0.3s;
      background: linear-gradient(129.54deg, #00b690 0.39%, #155f6f 235.62%);
      border-radius: 20px;
      &:hover {
        background: linear-gradient(129.54deg, #00b690 0.39%, #155f6f 235.62%);
      }
    }
  }
}

.landing-header-hero {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  padding-top: 80px;
  color: white;
  @media (max-width:961px)  {
    align-items: center;
  * {  text-align: center!important;}
   }


  h1 {
    font-family: Montserrat SemiBold;
    text-shadow: 2px 1px 4px #000a0b;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    // -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    // -webkit-text-stroke-width: 1px;
    // -webkit-text-stroke-color: white;
    @media (max-width:961px)  {
      font-size: 28px;
      line-height: 32px;
       }

    span {
      -webkit-text-fill-color: white;
      font-size: 2rem;
    }
  }
  p {
    margin-top: 10px;
    text-shadow: 2px 1px 4px #000a0b;
    font-family: Montserrat Medium;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    @media (max-width:961px)  {
     font-size: 15px;
     padding: 20px 20px 0px;
       }
  }
  button {
    background: linear-gradient(180deg, #00b690 0%, #0069b4 195%);
    border-radius: 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    border: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: white;
    padding: 10px 30px;
    margin-top: 30px;
  }
}
.landing-header-container {
  max-width: 1000px;
  width: 100%;
}
.landing-section1 {
  margin-top: -100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width:961px)  {
   margin-top: -40px;
         }

  .phone,
  .phone2 {
    filter: drop-shadow(30px 28px 30px rgba(51, 51, 51, 0.1));
    animation: fadein 0.8s;
    @media (max-width:961px)  {
      margin-left: 50px;
      width: 300px;
  }
  }
  &-caroussel {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;


    max-width: 1280px;
    width: 100%;
    position: relative;
    @media (max-width:961px)  {
      justify-content: center;
      flex-direction: column-reverse;
           }
    &-inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      padding: 0px 50px;
      @media (max-width:961px)  {
   padding: 0px 10px;
             }
    
    }

    .caroussel-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fadein 0.8s;
      h2 {
        padding-top: 100px;
        @media (max-width:961px)  {
       padding-top: 0;
       font-size:24px;
       line-height: 30px;
               }
        font-weight: 900;
      }
      p {
        padding: 20px 10px;
        max-width: 550px;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
      }
    }
  }
}
hr {
  width: calc(100vw - 50px);
  margin: 80px auto 50px;
}
.vermais {
margin-top: 10px;
padding: 10px;
cursor: pointer;
&:hover {
  font-weight: 600;
}
}
.landing-section2 {
  width: 100%;
  padding: 50px 0px;
  .rec-item-wrapper {
display: grid;
place-items: center;
height: 100%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-bottom: 50px;
  @media (max-width:961px)  {
    padding: 0px 10px;
              }
  &.cinza-section {
    background-image: url('../assets/imgs/bgLogin.JPG'),
    linear-gradient(to right top, #051937, #012946, #003955, #024962, #125a6d);
  background-blend-mode: overlay;

    background-attachment: fixed;
    background-position: top center;
    background-size: cover;
    @media (max-width: 961px) {
      background-attachment: local;
    }
    h2 {
      margin-top: 80px;
      color: white;
      font-weight: 900;
    }
    li {
      color: white;
    }
  }

  h2 {


    font-weight: 900;
    // -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    // -webkit-text-stroke-width: 2px;
    // -webkit-text-stroke-color: 1a1a1a;
  }

  &-caroussel {
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    max-width: 1280px;
    width: 100%;
    position: relative;

    ul {
      margin-top: 30px !important;
      display: flex;

      li {
        cursor: pointer;
        padding: 10px 50px;

        border-bottom: 2px solid rgba(0, 0, 0, 0.07);
        min-width: 220px;
        text-align: center;
        transition: ease all 0.8s;
        font-family: Montserrat SemiBold;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;

        &.active {
          border-bottom: 2px solid #00b690;
          color: #00b690;
        }
        &:hover {
          border-bottom: 2px solid #00b690;
          color: #00b690;
        }
      }
    }

    .caroussel-wrapper {
      display: flex;
      flex-direction: row;
      max-width: 920px;
      justify-content: center;
      align-items: center;
      background-color: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin: 30px 0px;
      height: 320px;
      .carrousel2-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 20px;
        animation: fadein 0.8s;
        h3 {
          width: 100%;
          text-align: left;
        }
        p {
          margin-top: 20px;
          font-size: 14px;
        }
      }
    }
  }
}
.landing-footer {
  position: relative;
  color: white;
  background-color: #000a0b;
  padding: 20px 0px;
  display: flex;
  .container {
    flex-direction: column;
    * + span {
      margin: 10px 0px;
    }
   }
  justify-content: center;
  @media (max-width: 961px) {

  }

}

.whatsapp {
  position: fixed;
  top: 30px;
  right: 20px;
  z-index: 10;
  width: 40px;
}

.bt1 {
  background: linear-gradient(178.17deg, #009b9d 1.55%, #6f1529 214.74%);

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  padding: 10px 30px;
  margin-top: 10px;
  transition: all ease 0.8s;
  margin-top: 30px;
  &:hover {
    background: linear-gradient(180deg, #00b690 0%, #0069b4 195%);
  }
}

/* square buttons */
.rec.rec-arrow {
  background-color: #f1f1f1;

  &:hover,
  &:hover:enabled,
  &:focus,
  &:enabled,
  &:focus:enabled {
    background-color: #f1f1f1;
  }

  &:hover:enabled,
  &:focus,
  &:enabled,
  &:focus:enabled {
    color: #333;
  }
}
.rec.rec-dot {
  background: #11111150;
}
.rec.rec-dot_active,
.rec.rec-dot_active:focus {
  background: #04a88b;
  box-shadow: 0 0 1px 0px #333;
}

.nonexpanded {
  display: -webkit-box;
-webkit-line-clamp: 6;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}
.imgmobile {
  border-radius: 10px;
  max-width: calc(100vw - 100px);
 margin: 0px auto;
}