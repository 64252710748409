@keyframes fadein {
  from {
    opacity: 0;
    padding-top: 30px;
  }
  to {
    padding-top: 0px;
    opacity: 1;
  }
}
.plans-wrapper {
  * {
    font-family: Montserrat !important;
  }
  nav {
    background-color: #000a0b40;
  }
  h2 {
    font-family: Montserrat SemiBold;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;

  }
}

.plans-header {
  height: 280px;


  background-image: url("../assets/imgs/bgLogin.JPG"),
  linear-gradient(to right top, #051937, #012946, #003955, #024962, #125a6d);
  background-blend-mode: soft-light;

  background-attachment: fixed;
  background-position: bottom center;
  // background-attachment: fixed;
  background-position: bottom center;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
}
.plans-header-wrapper {
  position: absolute;
  padding: 40px 0px;
  display: flex;
  justify-content: center;
  width: 100vw;
}

.plans-title {
  font-size: 48px;
  font-weight: 800;
  text-transform: uppercase;
  
  @media (max-width: 961px) {
    font-size: 24px;
  }
  
  text-shadow: 1px 2px 8px #333;
  
  width: 100%;
  text-align: left;
  color: white;
  // -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  margin-top: -92px;

}
.plans-title-h2 {
  font-size: 32px;

  margin-top: 30px;
  margin-top: 80px;
  margin-bottom: 20px;
  @media (max-width: 961px) {
    font-size: 22px;
    white-space: nowrap;
    padding-top: 20px;
  }
}

.column {
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  justify-content: center;
}

.plans-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.plans-flex {
  display: flex;
  
  max-width: 1200px;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 30px;

  @media (max-width: 961px) {
max-width: 100vw;
flex-direction: column;
  }

}
.plan {
  margin: 10px;
  display: grid;
  grid-template-rows: auto auto auto auto 1fr auto;
  justify-items: center;
  background-color: #f4f4f4;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.plan-title {
  text-align: center;
  padding: 10px 20px;
  color: WHITE;
  background: linear-gradient(179.54deg, #00b690 0.39%, #155f6f 235.62%);
  width: 100%;
  white-space: nowrap;
}
.plans-title {
  
  white-space: nowrap;
  text-align: center;
}

.plan-description {
  max-width: calc(100% - 20px);
  width: 800px;
  text-align: center;
  padding-bottom: 20px;
  span {

    font-weight: 700;
  }
}

.frequencia,
.pagamento,
.valor,
.descricao {
  text-align: center;
  max-width: calc(100%);
  
  padding: 10px 20px;
}
.valor {
  font-weight: 800;
  font-size: 32px;
  span {
    font-size: 12px;
  }
}

.frequencia,
.pagamento {
  font-weight: 700;
}
.descricao {
  font-size: 14px;
  line-height: 24px;
}
