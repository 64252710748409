.admin-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
  text-align: center;
  overflow-y: scroll;
}
.admin-flex-in {
  width: 90%;
}
.admin-training-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  position: relative;
}
.training-name {
  padding: 10px;
}
.training-headers {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  flex-direction: column;
}
.training-button-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
  button {
    margin: 0;
    border-radius: 0;
    width: 100%;
    margin: 10px 0px;
  }
}
.load {
  text-align: center;

  font-size: 9px;
}
.training-button {
  &.exercisebt {
    font-size: 9px;
  }
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 20px;

  margin-right: 0px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: #069f5e;
  color: white;
}
.training-form {
  background-color: #f9f9f9;
}
.training-input {
  border-radius: 5px;
  border: 1px gray solid;
  padding: 5px 10px;
  margin: 5px;
}
.training-delete {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #9999;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  &:hover {
    background-color: red;
  }
}
button {
  cursor: pointer;
}

.fundo {
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.bloco {
  width: 100%;

  padding: 50px;
  display: grid;

  grid-template-columns: 200px 20px 1fr;
  grid-template-rows: 1fr;

  justify-content: center;
  align-items: center;
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
}

.exercicises-form {
  padding: 40px 20px;
  border: solid 1px #33333333;
  border-radius: 25px;
}

.back-button {
  position: absolute;
  top: 0;
  left: 0;
}
