body {
  width: 100vw;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  color: #333;
}
* {
  outline: none;
}

.video-js {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 220px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.iconVideosHeader {
  margin-right: 20px;
  @media (max-width: 720px) {
    margin-right: 5px;
  }
}
.pagination {
  background-color: white;
  z-index: 20;
  position: fixed;
  z-index: 30;
  bottom: 70px;
  right: 0px;
  padding: 7px 10px;
  border-top-left-radius: 15px;

  padding-right: 40px;
  font-size: 0.6rem !important;
  @media only screen and (max-width: 720px) {
    width: 100%;
    border-top-left-radius: 0px;
    padding-right: 10px !important;
    display: flex;
    justify-content: center;
  }
}
.MuiButtonBase-root {
  color: black;
}
.MuiPaginationItem-root {
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 10px;
  @media only screen and (max-width: 800px) {
    margin: 0 0px !important;
    width: 100% !important;
    left: 0 !important;
  }
}
.MuiPaginationBase-root:hover {
  color: rgba(0, 0, 0, 0.8) !important;

  font-size: 10px;
}
.MuiPaginationItem-page.Mui-selected {
  background-color: rgba(0, 0, 0, 0.8) !important;
  background-image: linear-gradient(180.4deg, rgba(9, 30, 61, 1) 45.3%, rgba(95, 123, 190, 1) 279%);
  color: white !important;
}
.MuiPagination-ul li {
  font-size: 5px;
}
.MuiPagination-ul li:hover {
  padding: 0;
  margin-top: 0;
}

.header-img {
  padding: 5px;
  border-radius: 10px;
  color: white;
  background-color: #125a6d;
  border: none;
  height: 40px;
  min-width: 40px;
  width: auto;
  margin-bottom: 12px;
}
.header {
  z-index: 10px;
  a {
    margin: 5px;
  }
  button {
    padding: 5px 20px;
    border-radius: 10px;
    color: white;
    background-color: #125a6d;
    border: none;
    a {
      text-decoration: none;
      color: inherit;
    }
    cursor: pointer;
  }
  button:hover {
    background-color: #069f5e;
  }
  height: 100%;
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: white;
  background: #069f5e;
  // background-image: linear-gradient(
  //   to right top,
  //   #051937,
  //   #004d7a,
  //   #008793,
  //   #00bf72,
  //   #a8eb12
  // );
  background-image: linear-gradient(to right top, #051937, #012946, #003955, #024962, #125a6d);
  button {
    width: 100%;
  }

  .welcome {
    text-align: center;
    max-width: 200px;
    margin: 20px 0px;
    font-size: 0.8rem;
  }
  h2.title {
    font-size: 0.4rem;
  }
  .logo {
    padding: 0px 30px;
    width: 90%;
    min-width: 300px;
    max-width: 300px;
    @media only screen and (min-width: 720px) {
    }

    filter: drop-shadow(0px 0px 6px rgba(51, 51, 51, 0.1));
  }
  .headerNav {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Saira Extra Condensed', sans-serif;
    text-transform: uppercase !important;
    button {
      min-width: 150px;
    }
    @media (min-width: 720px) {
      flex-direction: column;
    }
  }
  .navMenu {
    text-align: center;
    padding: 30px 0px;
  }
}
.video-modal {
  position: fixed;
}
.videosHeader {
  z-index: 10;
  width: 100%;
  top: 0px;

  background-color: white;
  font-size: 1.4rem;
  width: 100%;
  padding: 20px 30px;

  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.videosSection {
  -webkit-box-shadow: inset 36px 0px 26px -40px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: inset 36px 0px 26px -40px rgba(0, 0, 0, 0.35);
  box-shadow: inset 36px 0px 26px -40px rgba(0, 0, 0, 0.35);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #333;
  background: #f5f7fa;
  scrollbar-width: thin;
  position: relative;

  .searchIcon {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .searchHolder {
    display: flex;
    position: fixed;
    bottom: 0;
    margin-left: -30px;
    width: 90%;
    height: 70px;
    z-index: 20;
    background-color: #eee;
    @media only screen and (max-width: 800px) {
      width: 100%;
    }

    -webkit-box-shadow: 0px 0px 26px 4px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 26px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 26px 4px rgba(0, 0, 0, 0.15);
  }
  input {
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 30px;
    padding-left: 30px;
    background-color: white;
  }
  &:focus {
    font-size: 30px;
    height: 100px;
    background-color: rgba(254, 255, 238, 1);
  }
}
video:-webkit-full-page-media {
  width: 100%;
  video {
    width: 100%;
    height: 100%;
  }
}
.videoLi {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 350px;
  margin: 20px;
  height: 100%;
  min-height: 222px;
  margin: 5px 5px;
  padding: 5px;
  width: 100%;

  @media only screen and (max-width: 800px) {
    margin: 7px 0px;
    border-radius: 0px;

    max-width: 90%;
  }
  @media only screen and (min-width: 1100px) {
    width: 350px;
  }

  transition: ease all 0.1s;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-bottom: 10px;
}
.videoLi:hover {
}
.loading-spin {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
  transition: 0.7s ease all;
  color: #024962;
}
.videoul > * {
  flex: 1 0 310px;
}
.video-ul {
  display: flex;
  width: 100%;
  height: max-content;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 140px !important;
}
iframe {
}
.videoid {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-family: 'Saira Extra Condensed', sans-serif;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
}
.appAluno {
  display: flex;
  flex-direction: column;
  height: auto;
  @media (min-width: 720px) {
    display: grid;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    grid-template-columns: 1fr 3fr;
  }
}

.Login {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;

  @media (min-width: 720px) {
    flex-direction: column;
    height: 100vh;
  }
  justify-content: center;
  align-items: center;
  background-image: url('../imgs/bgLogin2.jpg'),
    linear-gradient(to right top, #051937, #012946, #003955, #024962, #125a6d);
  background-blend-mode: overlay;
  background-size: cover;
  background-position: center;
  -webkit-box-shadow: 0px 0px 26px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 26px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 26px 4px rgba(0, 0, 0, 0.15);
  form {
    display: flex;
    flex-direction: column;
    width: 80%;

    @media only screen and (max-width: 720px) {
      max-width: 300px;
    }
    @media only screen and (min-width: 720px) {
      width: 300px;
    }
    input {
      padding: 5px 10px;
      margin: 5px 0px;
      border-radius: 5px;
      border: none;
    }
    button {
      margin-top: 5px;
      width: 100%;
      font-weight: bold;
    }
    a.novo {
      color: white !important;
      font-size: 14px;
      text-align: center;
      padding-top: 20px;
    }
  }
}
.video-js .vjs-tech {
  background-image: linear-gradient(to right top, #051937, #012946, #003955, #024962, #125a6d);
  // background-blend-mode: overlay;
  background-size: cover;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.title div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 720px) {
    margin-right: 15px;
  }
  h1 {
    font-size: 1rem;
    padding-bottom: 0px !important;
    white-space: nowrap;
  }
  strong {
    font-size: 1rem;
    margin-right: 20px;
    @media (max-width: 720px) {
      font-size: 0.9rem;
      margin-right: 15px;
    }
  }
  button {
    margin-top: 0px;
    font-size: 1rem;
    padding: 5px 20;
  }
}

.modal-video-close-btn {
  display: none !important;
}
.modal-video {
  background-color: #04203d99 !important;
}
