.archives-wrapper {
    display: flex;
    flex-direction: column;
    padding: 70px 20px 20px;
    width: 100%;
    a {
        text-decoration: none;
    }
}

.archives-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    transition: ease 0.3s all;
    span {

        margin-left: 10px;
        color: black;
        text-decoration: none;
    }
  &:hover {
      background-color:#111;
      span {

        margin-left: 10px;
        color: white;
        text-decoration: none;
    }
  }

    img {
        width: 30px;
    }
}
.admin-pdf-list {
    display: flex;
    width: 500px;
    justify-content: space-between;
    margin: 20px 0px;
    button {
        padding: 5px 20px;
        border-radius: 10px;
        color: white;
        background-color: #125a6d;
        border: none;

          text-decoration: none;

        cursor: pointer;
      }
}
.pdf-submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    * + * {
        margin-top:20px;
    }
    input {
        width: 100%;
    }
    button {
        padding: 5px 20px;
        border-radius: 10px;
        color: white;
        background-color: #125a6d;
        border: none;

          text-decoration: none;

        cursor: pointer;
      }
}
.list-title {
    margin: 60px 0px 20px;
}