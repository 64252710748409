.users-table {
  width: 100%;
  button {
    margin: 5px;
  }
}
.admin-training-section {
  button {
    border: none;
    padding: 10px 20px;
    font-weight: 600;
  }
}

.adminTr {
  background-color: #f6f6f6;
  color: #444;
}
.novo { margin-bottom: 30px;  background-color: #444; color: white; font-weight: 600; text-decoration: none;
padding: 10px 20px;}

// .button-delete {
//   background-color: red;
// }
.table-title {
  margin-bottom: 20px;
  font-size: 2rem;
}