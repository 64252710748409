*:focus {
  outline: none;
}

.training {
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
}
.mobile {
  @media (min-width: 830px) {
    display: none !important;
  }
}
.desktop {
  @media (max-width: 830px) {
    display: none;
  }
}
.favideo {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 40px;
  height: 23px;
  z-index: 10;
  border-radius: 20px;
  color: white;
  -webkit-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.75);
  background-color: #069f5e;
}
.thumb-gif {
  width: 100%;
  border-radius: 10px;

  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}
@import "node_modules/react-modal-video/scss/modal-video.scss";
.atividadesSection {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  height: 100%;
  width: 100%;

  background: #333;
  background: #f5f7fa;
  scrollbar-width: thin;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  padding-top: 0px;

  @media (min-width: 720px) {
    margin-bottom: 100px;
    overflow-y: scroll;
  }
  h1 {
    font-size: 2.5rem;
    color: #333;
    padding-bottom: 30px;
  }
  h2 {
    font-size: 32px;
    font-family: "Saira Extra Condensed", sans-serif;
    font-weight: 400;
    width: 100%;

    // background-color: #167f92;
    color: #333;

    text-align: center;
    @media (max-width: 830px) {
      font-size: 1.5rem;
    }
  }
  .childTr {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    tr {
      font-size: 0.8rem;
      width: 100%;
      height: 100%;
      padding: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
    }
  }
  .obsTh {
    display: none;
    @media (min-width: 948px) {
      display: table-cell;
    }
  }
  .obsTd {
    display: none;
    font-size: 0.8rem;

    @media (min-width: 948px) {
      display: table-cell;
    }
  }
  .exerciciosTh {
    min-width: 200px;
    text-align: center;
    @media (max-width: 834px) {
      min-width: 100%;
    }
  }
  .sequenciaTd {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center !important;
    position: relative;
  }

  @media (max-width: 834px) {
    font-size: 1rem;
  }
}

.exerciciosTd {
  font-size: 0.8rem;
  font-weight: 700;
  width: 100%;

  .serieTd {
    font-size: 0.8rem;
    font-weight: 700;
    width: 100%;
  }
}
$table-breakpoint: 843px;
$table-background-color: #fff;
$table-text-color: #024457;
$table-outer-border: 1px solid #167f92;
$table-cell-border: 1px solid #d9e4e6;

$table-border-radius: 10px;
$table-highlight-color: #eaf3f3;
$table-header-background-color: #167f92;
$table-header-text-color: #fff;
$table-header-border: 1px solid #fff;

@mixin responstable(
  $breakpoint: $table-breakpoint,
  $background-color: $table-background-color,
  $text-color: $table-text-color,
  $outer-border: $table-outer-border,
  $cell-border: $table-cell-border,
  $border-radius: none,
  $highlight-color: none,
  $header-background-color: $table-background-color,
  $header-text-color: $table-text-color,
  $header-border: $table-cell-border
) {
  .tableWrapper {
    width: 100%;
  }
  .tableWrapperView {
    width: 100%;
    padding-top: 20px;
    margin-top: 40px;
    max-width: 450px;
    @media only screen and (max-width: 830px) {
      margin-top: 40px;
    }
  }
  .responstable {
    margin: 1em 0px;
    width: 100%;
    overflow: visible;
    background: $background-color;
    color: $text-color;
    border-radius: $border-radius;
    border: $outer-border;

    tbody {
      border: $cell-border;

      &:nth-of-type(odd) {
        background-color: $highlight-color !important;
      }
      &:nth-of-type(even) {
        background-color: white !important;
      }
    }

    th {
      display: none;
      border: $header-border;
      background-color: $header-background-color;
      color: $header-text-color;

      &:first-child {
        display: table-cell;
        text-align: center;
      }
      &:nth-child(2) {
        // show the second table header but replace the content with the data-th from the markup for mobile
        display: table-cell;
        span {
          display: none;
        }
        &:after {
          content: attr(data-th);
        }
      }
      @media (min-width: $breakpoint) {
        &:nth-child(2) {
          // hide the data-th and show the normal header for tablet and desktop
          span {
            display: block;
          }
          &:after {
            display: none;
          }
        }
      }
    }

    td {
      display: block; // display the table data as one block for mobile
      word-wrap: break-word;
      max-width: 100%;

      text-align: center;

      &:first-child {
        display: table-cell; // display the first one as a table cell (radio button) for mobile
        text-align: center;
        border-right: $cell-border;
      }
      @media (min-width: $breakpoint) {
        border: $cell-border;
        max-width: 7em;
      }
    }

    td {
      text-align: center;

      @media (min-width: $breakpoint) {
        display: table-cell; // show the table as a normal table for tablet and desktop
        padding: 0em;
        text-align: center;
      }
    }
    th {
      text-align: left;
      margin: 0.5em 1em;
      text-transform: uppercase;
      @media (min-width: $breakpoint) {
        display: table-cell; // show the table as a normal table for tablet and desktop
        padding: 0.5em;
        text-align: center;
        font-size: 0.8rem;
      }
    }
  }
}

@include responstable(
  $border-radius: $table-border-radius,
  $highlight-color: $table-highlight-color,
  $header-background-color: $table-header-background-color,
  $header-text-color: $table-header-text-color,
  $header-border: $table-header-border
);
.tabelaTraining {
  width: 100%;
  padding: 0px 50px;
  padding-bottom: 50px;

  @media (max-width: 720px) {
    padding: 10px;
  }
  background-color: #02445710;

  &:nth-child(odd) {
    background-color: white;
  }
}
.tabela-header {
  z-index: 10;
  width: 100%;
  top: 0px;
  position: sticky;
  display: flex;
  justify-content: center;
  padding: 20px 30px;
  background-color: #024457;

  // background-image: linear-gradient(
  //   to right top,
  //   #051937,
  //   #004d7a,
  //   #008793,
  //   #00bf72,
  //   #a8eb12
  // );
  @media (max-width: 830px) {
    overflow-x: scroll;
    overflow-y: visible;
    justify-content: flex-start;
  }

  button {
    font-family: "Saira Extra Condensed", sans-serif;
    box-sizing: border-box;
    padding: 10px 40px;
    font-size: 22px;
    background-color: #ffffff90;
    margin: 0 10px;
    white-space: nowrap;
    border: none;
    border-radius: 10px;
    color: #333;
    font-weight: 600;
    letter-spacing: 1px;
    @media (max-width: 830px) {
      margin: 0 5px;
      padding: 5px 40px;
    }

    &:hover {
      background-color: #167f92;
      color: white;
    }
    &:focus {
      outline: 0;
    }
  }
}

.loadingdiv {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

/* Transparent Overlay */
.loadingdiv:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #167f9280;
}

/* :not(:required) hides these rules from IE9 and below */
.loadingdiv:not(:required) {
  /* hide "loadingdiv..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loadingdiv:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.trainModeGif {
  display: flex;
  width: 100%;
}
.descanso {
  margin-top: 5px;
  padding: 5px 20px;
  width: 100%;
  background-color: #33333309;
  color: #333;
  text-align: center;
  display: flex;
  font-size: 13px;
  font-weight: 600;

  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 830px) {
    font-size: 10px;
  }
}
.descansoView {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 40px 0px 50px;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  background-color: #d9e4e6;
  border-radius: 10px;
  img {
    width: 50px;
  }
  h3 {
    margin: 10px;
  }

  @media only screen and (max-width: 830px) {
    margin: 40px 0px 40px;
    position: relative !important;

    z-index: 1;
  }

  h3 {
    margin-bottom: 10px;
  }
  p {
    padding: 10px 0px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    @media only screen and (max-width: 830px) {
      font-size: 10px;
    }
  }
}
.gifModal {
  opacity: 0;
  display: none;
  height: 0;
  overflow: hidden;
  position: fixed;
  left: 10px;
  top: calc(50vh + 120px);
  transform: translate(0%, -50%);
  z-index: 4;
  border: 2px solid white;
  background-color: #167f92;
  padding: 2px;
  border-radius: 20px;
  transition: all 0.2s ease-in;
  @media only screen and (max-width: 830px) {
    top: calc(50vh + 150px);
    left: 50vw;
    width: 300px;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  img {
    border-radius: 18px;
  }
}
.descansoView {
  &:last-child {
    display: none;
  }
}
.sequenciaItemView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  &:first-child {
    margin-top: 0px;
  }

  &:only-child {
    margin: 20px 0px;
  }
  position: relative;

  hr {
    display: none;
  }

  &.same-sequence {
    margin-top: 40px;

    &:last-child {
      margin-bottom: 40px;
    }

    &::before {
      content: "\25BC";
      line-height: 1rem;
      font-size: 1rem;
      width: 32px;
      text-align: center;
      position: absolute;
      top: -77px;
      margin-top: 40px;
      background-color: #167f92;
      padding: 9px 7px;
      border-radius: 50px;
      color: white;
    }
    //  hr {

    //    display: block!important;
    // margin:0px!important;
    // border: 5px solid #167f92;
    //   }
  }
}
.gifNameView {
  position: absolute;
  background-color: #00000090;
  opacity: 0.9;

  bottom: 0px;
  left: 0;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
  text-align: center;
  padding: 1px 15px;
  border-top-right-radius: 20px;
  @media only screen and (max-width: 830px) {
    font-size: 0.7rem;
  }
}
.infoView {
  position: absolute;
  top: 0px;
  right: 0;
  display: flex;
  padding: 10px;
  padding-top: 0px;

  div {
    background-color: white;
    padding: 10px;
    margin-right: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 13px;
    @media only screen and (max-width: 830px) {
      padding: 7px 7px 10px;
    }
    .iconeView {
      margin-right: 7px;
      width: 12px;
      filter: brightness(0);
    }
  }
}

.itemHolder {
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  background-color: #167f92;
  transition: ease all 0.3s;
  min-height: 250px;
  @media only screen and (max-width: 830px) {
    width: calc(100% - 40px);
    min-height: 200px;
    margin: 0px 20px;
  }
  &:hover {
    // border: 2px solid #024457;
    filter: brightness(1.1);
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}
.sequenciaNameView {
  position: absolute;
  padding: 10px;
  background-color: #167f92;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  border-bottom-right-radius: 20px;
  h4 {
    font-size: 0.5rem;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 830px) {
    font-size: 1.4rem;
  }
}
.giftd {
  cursor: pointer;
  position: relative;
  overflow: visible;
  font-size: 0.8rem;
  padding-bottom: 25px !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  span {
    font-size: 0.6rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    opacity: 0.5;
  }
  &:hover {
    background-color: #02445740;

    .gifModal {
      height: auto;
      display: block;
      opacity: 1;
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 830px) {
    font-size: 0.6rem;
    padding-bottom: 30px !important;
  }
  &:hover {
  }
}
.rest {
  width: 50px;
}

.changeView {
  position: absolute;
  bottom: -47px;
  left: 20px;
  z-index: 20;
  .viewIcon {
    margin-right: 20px;
    padding: 2px;
    color: #167f92;
    cursor: pointer;
    &.active {
      color: #069f5e;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}

.changeView2 {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 5;

  padding: 12px;
  padding-right: 6px;
  background-color: white;
  border-top-right-radius: 25px;
  .viewIcon {
    margin-right: 20px;
    &:last-child {
      margin-right: 10px;
    }
    color: #167f92;
    cursor: pointer;
    &.active {
      color: #069f5e;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}
.tabelaView {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: "Saira Extra Condensed", sans-serif;

    width: 100%;
    color: white;
    letter-spacing: 1px;
    background-color: #004d67;
    margin-top: 0px !important;
    margin-bottom: -40px;
    z-index: 4;
    @media (max-width: 830px) {
      position: sticky !important;
      top: 83px;
      font-size: 1.5rem;
    }
  }
}
.active-sequence {
  background-color: #069f5e !important;
  color: white !important;
}
.navlink button,
.navlink img {
  background-color: #069f5e !important;
  color: white !important;
}
.bt-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
td.desktop {
  @media only screen and (max-width: 830px) {
    display: none !important;
  }
}
.tdflex {
  @media only screen and (min-width: 830px) {
    display: none !important;
  }
  @media only screen and (max-width: 830px) {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 15px;
    filter: brightness(0);
    height: auto;
    margin-right: 10px;
    opacity: 0.9;
  }
}
.fundo {
  background-color: #ffffff50;
}
td {
  padding: 10px !important;
}
.modal-video-movie-wrap {
  border-radius: 20px;
  * {
    border-radius: 20px;
  }
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
.react-pdf__Page__textContent {
  display: none !important;
}
.pdf-buttons {
  p {
    text-align: center;
    width: 100%;
  }

  button {
    overflow: hidden !important;
    &:nth-child(even) {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    &:nth-child(odd) {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    background-color: #069f5e !important;
    color: white !important;
    border: none;

    @media (min-width: 720px) {
      margin-bottom: 100px;
      overflow-y: scroll;
      padding: 5px 20px;
    }
  }
}
.sequenciaTr {
  text-align: center !important;
}

video:-webkit-full-page-media {
  max-width: 100vw !important;
}

.register {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 100px 10px;
  background-color: #069f5e;
  button {
    margin: 5px;
  }
  form {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    input {
      margin: 10px;
      width: 300px;
      max-width: 90%;
      padding: 10px 20x;
    }
  }
}
